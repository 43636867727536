import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "__barrel_optimize__?names=ConfigProvider!=!/root/sck-on-fsd/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/sck-on-fsd/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/sck-on-fsd/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/sck-on-fsd/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/sck-on-fsd/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/root/sck-on-fsd/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/city/[city]/main/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"cyrillic\",\"latin\"]}],\"variableName\":\"inter\"}");
